<template>
	<div class="store">
		<div class="container">
			<div class="main-loading" v-if="!isContentReady">
				<loader-content :isDisplay="true"></loader-content>
			</div>
		</div>

		<template v-if="isContentReady">
			<store-detail :store="storeData" :topBanner="topBanner"></store-detail>
		</template>

		<div class="container">
			<div class="content-container mt-3">
				<div class="filter" v-if="isContentReady && storeData.isRegisteredSeller">
					<div class="content-mobile-filter-modal" :class="{ 'active': isShowMobileFilter }" @click="toggleFilterMobile()">
						<div class="box" @click.stop>
							<perfect-scrollbar>
								<div class="filter-title">
									<b-icon icon="sliders" class="icon"></b-icon> กรองผลการค้นหา <b-icon icon="x" @click="toggleFilterMobile()" class="close-icon"></b-icon>
								</div>
								<filter-search
									v-if="filterOptions && tags"
									ref="filterSearch"
									:filterOptions="filterOptions"
									:tagSelections="tags"
									@filterResult="changeFilter($event)">
								</filter-search>
							</perfect-scrollbar>
							<div class="operation-panel">
								<div class="wrapper">
									<button class="btn btn-reset btn-outline-secondary" @click="resetFilter()">รีเซ็ต</button>
									<button class="btn btn-main d-xl-none" @click="toggleFilterMobile()">ดูสินค้า {{ totalResult }} รายการ</button>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="content-body">
					<template v-if="isContentReady">
						<template v-if="storeData.isRegisteredSeller">
							<store-product-searcher @searchTire="searchTire($event)" class="mt-3"></store-product-searcher>

							<div class="list mt-3">
								<div class="content-mobile-filter mb-3">
									<button @click="toggleFilterMobile()"><b-icon icon="sliders" class="filter-icon"></b-icon> กรองผลการค้นหา</button>
								</div>
								<product-total-result class="mb-3" :totalResult="totalResult"></product-total-result>

								<gadget-sort-bar :sortOption="sortOption" :defaultOption="sortOption[0].id" @changeSort="changeSort($event)" class="mt-3 mb-3"></gadget-sort-bar>

								<div class="loading" v-if="!isResultReady">
									<div class="item">
										<loader-content :isDisplay="true"></loader-content>
									</div>
								</div>

								<template v-if="isResultReady">
									<gadget-search-tag :tags="tags" @removeTag="removeTag($event)" class="mb-3" v-if="tags && tags.length > 0"></gadget-search-tag>
									<product-grid
										:products="resultList"
										:type="constants.ITEM_BOX_TYPE.PURCHASE"
										:store="storeData"
										trackingReferrer="store"
										@purchaseDelivery="openDeliveryChecker($event)">
									</product-grid>
									<paging
										@page="changePage($event)"
										:activePage="activePage"
										:totalPage="totalPage"
										class="mt-4 mb-2">
									</paging>
								</template>
							</div>
						</template>
						<template v-else>
							<div class="no-data">
								<b-icon icon="box-seam"></b-icon>
								<span>ร้านค้านี้ยังไม่ได้ลงทะเบียนขายสินค้ากับ YELLOWTiRE</span>
							</div>

							<div class="store-list mt-3">
								<div class="content-bar mb-2">
									<div class="bar-title">ร้านยางและศูนย์บริการใกล้เคียงพร้อมติดตั้ง</div>
									<router-link :to="routerPath.STORE" class="view-more" title="ค้นหาร้านยาง">ดูทั้งหมด <b-icon icon="chevron-right"></b-icon></router-link>
								</div>
								<div class="loading" v-if="!isStoreListReady">
									<div class="item">
										<loader-content :isDisplay="true"></loader-content>
									</div>
								</div>
								<store-grid
									:stores="storeList"
									grid="column-6"
									v-if="isStoreListReady">
								</store-grid>
							</div>
						</template>
					</template>
				</div>
			</div>

			<modal-store-delivery-checker
				@close="closeDeliveryChecker()"
				@purchaseDelivery="openDeliveryChecker($event)"
				:isDisplay="isOpenDeliveryChecker"
				:product="deiveredProduct"
				:storeId="helper.splitDotParam($route.params.id)"
				trackingReferrer="store">
			</modal-store-delivery-checker>
		</div>
	</div>
</template>

<script>
import Helper from '@/services/helper';
import StoreService from '@/services/storeService';
import RouterPath from '@/router/path';
import OptionProductSort from '@/services/staticOption/productSort';
import Constants from '@/variables/constants';
import FilterSearch from '@/components/gadget/FilterSearch';
import ProductGrid from '@/components/product/ProductGridList';
import ProductTotalResult from '@/components/product/TotalResult';
import GadgetSortBar from '@/components/gadget/SortBar';
import GadgetSearchTag from '@/components/gadget/SearchTag';
import StoreDetail from '@/components/store/StoreDetail';
import StoreProductSearcher from '@/components/store/ProductSearcher';
import ModalStoreDeliveryChecker from '@/components/store/ModalDeliveryChecker';
import StoreGrid from '@/components/store/StoreGridList';

export default {
	components: {
		FilterSearch,
		ProductGrid,
		ProductTotalResult,
		GadgetSortBar,
		GadgetSearchTag,
		StoreDetail,
		StoreProductSearcher,
		ModalStoreDeliveryChecker,
		StoreGrid
	},
	data() {
		return {
			constants: Constants,
			routerPath: RouterPath,
			helper: Helper,
			isContentReady: false,
			isResultReady: false,
			isShowMobileFilter: false,
			topBanner: null,
			tags: [],
			storeData: null,
			filterOptions: [],
			resultList: [],
			totalResult: 0,
			activePage: 1,
			totalPage: 0,
			filter: null,
			sortOption: OptionProductSort.option,
			sortType: '',
			tireCriteria: {
				width: '',
				ratio: '',
				diameter: ''
			},
			deiveredProduct: null,
			isOpenDeliveryChecker: false,
			canonicalUrl: '',
			isStoreListReady: false,
			storeList: []
		};
	},
	mounted() {
        this.getContent();
    },
    methods: {
        async getContent() {
            this.isContentReady = false;

			const param = this.setupParamStore();
            const result = await StoreService.getStoreDetail(param);

			if (result.status == 404) {
				this.$router.push('/404');
			} else if (result.data) {
				this.canonicalUrl = location.protocol + "//" + window.location.host + RouterPath.STORE + '/' + decodeURI(result.data.storeData.param);
				this.topBanner = result.data.ads;
				this.filterOptions = result.data.filter;
				this.storeData = result.data.storeData;

				if (this.storeData.isRegisteredSeller) {
					this.getProductResult();
				} else {
					this.getOtherStoreList();
				}
			} else {
				this.$router.push(RouterPath.NOT_FOUND);
			}

			this.isContentReady = true;
        },
		async getProductResult() {
            this.isResultReady = false;

			const param = this.setParamProduct();
            const result = await StoreService.searchProduct(param);

			this.resultList = result.data.resultList;
			this.totalResult = result.data.totalResult;
			this.tags = result.data.tags;
			this.totalPage = result.data.totalPage;
            this.isResultReady = true;
        },
		getStoreId() {
			return Helper.splitDotParam(this.$route.params.id)
		},
		setupParamStore() {
			const param = {
				id: this.getStoreId(),
				urlReferrer: document.referrer
			};

			return param;
		},
		setParamProduct() {
			const param = {
				storeId: this.getStoreId(),
				page: this.activePage,
				width: this.tireCriteria.width,
				ratio: this.tireCriteria.ratio,
				diameter: this.tireCriteria.diameter,
				filter: this.filter,
				sort: this.sortType
			};

			return param;
		},
		changeFilter(data) {
			this.filter = data.filter;
			this.changePage(1);
		},
		changePage(page) {
			this.activePage = page;
			this.getProductResult();
		},
		changeSort(data) {
			this.sortType = data.value;

			if (this.sortType) {
				this.getProductResult();
			}
		},
		searchTire(data) {
			this.tireCriteria = {
				width: data.width,
				ratio: data.ratio,
				diameter: data.diameter
			};

			this.changePage(1);
		},
		toggleFilterMobile() {
			this.isShowMobileFilter = !this.isShowMobileFilter;
			this.$root.$emit('rootSetContentScrollable', this.isShowMobileFilter);
		},
		resetFilter() {
			this.$refs.filterSearch.resetFilter();
		},
		removeTag(data) {
			const tagIndex = this.tags.findIndex(x => x.value === data.item.value);
			this.tags.splice(tagIndex, 1);
			this.$refs.filterSearch.updateFilterSelection(this.tags);
		},
		openDeliveryChecker(data) {
			this.deiveredProduct = data;
			this.isOpenDeliveryChecker = true;
		},
		closeDeliveryChecker() {
			this.isOpenDeliveryChecker = false;
		},
		async getOtherStoreList() {
			this.isStoreListReady = false;
			const position = this.geolocationState === 'granted' ? await new Promise((resolve, reject) => {
				navigator.geolocation.getCurrentPosition(resolve, reject);
			}) : null;

			const result = await StoreService.getRelatedStoreList(this.getStoreId(), position);

			this.storeList = result.data.resultList;
            this.isStoreListReady = true;
		},
    },
	metaInfo() {
		const title = this.storeData ? this.storeData.name + ' | YELLOWTiRE': Helper.metaDefault().title;
		const desc = this.storeData ? this.storeData.description : Helper.metaDefault().desc;
		const image = this.storeData ? this.storeData.picture : location.origin + Helper.metaDefault().logo;
		
        return {
            title: title,
            meta: [
				{ vmid: 'author', name: 'author', content: Helper.metaDefault().author },
				{ vmid: 'publisher', name: 'publisher', content: Helper.metaDefault().publisher },
				{ vmid: 'keyword', name: 'keywords', content: Helper.metaDefault().keyword },
                { vmid: 'desc', name: 'description', content: desc },
                { vmid: 'og-url', property: 'og:url', content: location.href },
                { vmid: 'og-title', property: 'og:title', content: title },
                { vmid: 'og-desc', property: 'og:description', content: desc },
                { vmid: 'og-type', property: 'og:type', content: 'website' },
                { vmid: 'og-image', property: 'og:image', content: image },
                { vmid: 'twitter-description', property: 'twitter:description', content: desc },
                { vmid: 'twitter-title', property: 'twitter:title', content: title },
                { vmid: 'twitter-image', property: 'twitter:image', content: image }
            ],
			link: [
				{ rel: 'canonical', href: this.canonicalUrl }
			]
        }
	}
}
</script>

<style lang="scss" scoped>
@import '@/theme/Variable.scss';

.store {
	padding: 0 0 15px;

	.main-loading {
		height: 300px;
	}

	.list {
		position: relative;

		.loading {
			.item {
				height: 250px;
			}
		}
	}

	.no-data {
		padding: 30px 0;

		span {
			padding-top: 20px;
		}
	}

	.store-list {
		.loading {
			.item {
				height: 150px;
			}
		}
	}
}
</style>